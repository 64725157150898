<template>
  <section class="reg">
    <div class="logo">
      <div class="fl"><img src="../../../assets/images/h5-logo-2x.png" /></div>
      <div class="fr" @click="changeRouterRule()">活动规则</div>
    </div>
    <div class="pri-text" :class="{ lg: coupons.length > 2 }">
      <img src="../../../assets/images/h5-gongxihuode-2x.png" />
    </div>
    <div class="coupon" :class="{ lg: coupons.length <= 3 }">
      <div class="item" v-for="(item, index) in coupons" :key="index">
        <div class="price">
          <div class="num" v-if="item.cpnType == 0">
            <b>{{ item.credit }}</b>元
          </div>
          <div class="num" v-else-if="item.cpnType == 1">
            <b>{{ item.credit }}</b>元
          </div>
          <div class="num" v-else-if="item.cpnType == 2">
            <b>{{ convertDiscont(item.discount) }}</b>折
          </div>
          <div>{{ formatDate(item.expireTime, "yyyy-MM-dd") }}到期</div>
        </div>
        <div class="remark" v-if="item.cpnType == 0">
          <b>{{item.actCpnName}}</b>
          <div>无门槛使用</div>
        </div>
        <div class="remark" v-else-if="item.cpnType == 1">
          <b>{{ item.actCpnName }}</b>
          <div>订单满{{ item.astrict || "0" }}元可用</div>
        </div>
        <div class="remark" v-else-if="item.cpnType == 2">
          <b>{{ item.actCpnName }}</b>
          <div>最高可抵扣{{ item.ceiling || "0" }}元</div>
        </div>
      </div>
    </div>

    <div class="footer" :class="{ fixed: coupons.length >= 3 }">
      <button class="download-btn" @click="changeRouterDownload()">
        下载礼帽出行APP
      </button>
    </div>
  </section>
</template>

<script>
import { Toast } from "vant";
import { getCoupon, sendDownload } from "@/api/reg";
import { formatDate } from "@/filters/index";

export default {
  data() {
    return {
      formatDate: formatDate,
      coupons: [
        // {
        //   actCpnName: "尊享体验券", //优惠券名称
        //   ceiling: 20.0, //（折扣的）上限金额（为零的话，则认为没有限制）
        //   cpnType: 2, //优惠券类型（1：满减 credit；2：折扣 discount）
        //   discount: 0.601, //（折扣的）折扣率（如，八折：0.8；九五折：0.95）
        //   expireTime: 1637251199000, //过期时间(时间戳格式)
        //   uuid: "33f6b36e64e64d338517ca1230a9b1d7", //乘客优惠券唯一id
        // },

        // {
        //   actCpnName: "尊享体验券", //优惠券名称
        //   astrict: 20.0, //（满减的）条件金额
        //   cpnType: 1, //优惠券类型（1：满减 credit；2：折扣 discount）
        //   credit: 20.0, //（满减的）扣除金额
        //   expireTime: 1637596799000, //过期时间
        //   uuid: "c05f3af40e2d4859bedfaa97758d022e", //乘客优惠券唯一id
        // },
        // {
        //   actCpnName: "尊享体验券", //优惠券名称
        //   ceiling: 20.0, //（折扣的）上限金额（为零的话，则认为没有限制）
        //   cpnType: 2, //优惠券类型（1：满减 credit；2：折扣 discount）
        //   discount: 0.601, //（折扣的）折扣率（如，八折：0.8；九五折：0.95）
        //   expireTime: 1637251199000, //过期时间(时间戳格式)
        //   uuid: "33f6b36e64e64d338517ca1230a9b1d7", //乘客优惠券唯一id
        // },

        // {
        //   actCpnName: "尊享体验券", //优惠券名称
        //   astrict: 20.0, //（满减的）条件金额
        //   cpnType: 1, //优惠券类型（1：满减 credit；2：折扣 discount）
        //   credit: 20.0, //（满减的）扣除金额
        //   expireTime: 1637596799000, //过期时间
        //   uuid: "c05f3af40e2d4859bedfaa97758d022e", //乘客优惠券唯一id
        // },
      ],
    };
  },
  components: {},
  mounted() {
    this.$store.commit("loading/HIDELOADING");
    this.getCoupon();
  },
  methods: {
    convertDiscont(num) {
      if (!num) {
        return "-";
      }
      return Number((num*10).toFixed(2))
    },
    getCoupon() {
      getCoupon({
        pUuid: this.$route.query.pUuid,
        regActUuid: this.$route.query.regActUuid,
      }).then((res) => {
        if (res.returnCode === 10000) {
          this.coupons = res.data.couponList;
        } else {
          Toast(res.msg);
        }
      });
    },
    changeRouterRule() {
      this.$router.push({
        path: "/rule",
        query: {
          id: this.$route.query.regActUuid,
        },
      });
    },
    changeRouterDownload() {
      sendDownload({
        regActUuid: this.$route.query.regActUuid,
      });
      window.location.href = "/download.html";
    },
  },
};
</script>

<style scoped lang="scss">
body {
  background-color: #ffec00;
}
.reg {
  position: relative;
  width: 100%;
  height: 100%;
  background: url("../../../assets/images/reg-suc-bg-2x.png") no-repeat;
  background-size: cover;

  .logo {
    padding: 20px 24px;
    color: #ffd9b8;
    overflow: hidden;
    img {
      height: 30px;
    }
    .fl {
      float: left;
    }
    .fr {
      float: right;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 20px;
    }
  }
  .pri-text {
    margin-bottom: 20px;
    margin-top: 0px;
    &.lg {
      margin-bottom: 0px;
      margin-top: -8px;
    }
    img {
      width: 90%;
    }
  }
  .coupon {
    width: 90%;
    margin: 0 auto;
    margin-top: -4px;
    // height: calc(100% - 335px);
    overflow-y: scroll;
    &.lg {
      height: auto;
      margin-top: 0px;
    }
    .item {
      width: 100%;
      height: 120px;
      padding: 20px 0;
      background: url("../../../assets/images/h5-coupon-2x.png") no-repeat;
      background-size: 100% 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      .price {
        width: 43%;
        font-size: 12px;
        font-weight: 400;
        font-family: PingFangSC-Regular, PingFang SC;
        color: #aaa;
        line-height: 12px;
        margin-top: -11px;
        .num {
          height: 44px;
          margin-bottom: 2px;
          b {
            font-size: 44px;
            font-weight: bold;
            color: #222;
            line-height: 44px;
          }
          font-size: 16px;
          font-weight: 600;
          color: #222;
          line-height: 16px;
        }
      }
      .remark {
        width: 53%;
        padding-left: 4%;
        font-size: 14px;
        font-weight: 400;
        color: #999;
        font-family: PingFangSC-Regular, PingFang SC;
        line-height: 14px;
        text-align: left;
        b {
          margin-bottom: 6px;
          display: inline-block;
          font-size: 18px;
          font-weight: 700;
          color: #222;
          line-height: 22px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: inline-block;
          max-width: 98%;
        }
      }
    }
  }
  @media screen and (max-height: 1200px) {
    .footer {
      margin: 31px auto 40px;
    }
    .coupon {
      height: calc(100% - 280px);
    }
    .fixed {
      margin: 0 auto 36px;
    }
  }
  @media screen and (min-height: 600px) {
    .footer {
      margin: 62px auto 40px;
    }
    .coupon {
      height: calc(100% - 321px);
    }
    .fixed {
      margin: 0 auto 40px;
    }
  }
  .footer {
    // margin-top: -36px;
    // margin: 62px auto 40px;
    > div {
      margin: 62px auto 40px;
      font-size: 14px;
      font-weight: 400;
      color: #866b5d;
      line-height: 20px;
    }
    &.fixed {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      //   margin: 0 auto 40px;
      //   padding: 18px 30px 40px;
      //   background: url("../../../assets/images/h5-mask-2x.png") no-repeat;
      //   background-size: cover;
    }
  }
  .download-btn {
    // margin: 0 auto 24px;
    width: 85%;
    height: 50px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffec00;
    line-height: 22px;
    background: url("../../../assets/images/h5-btn-2x.png") no-repeat;
    background-size: 100% 100%;
    border: none;
  }
}
</style>
